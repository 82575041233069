.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #303030;
  text-align: left;
}

.input-template {
  width: 400px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  margin-bottom: 20px;
}

.padding-5 {
  padding: 9px;
}
.buttons-line {
  width: 100%;
  text-align: end;
  background-color: gray;
  padding: 10px;
  margin-top: 50px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.container-configure {
  display: flex;
  flex-direction: column;
}

.bolder {
  font-weight: bolder;
}

.overlay-text {
  position: absolute;
  top: 60%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.header {
  font-weight: bold;
}

.footer {
  color: rgba(48, 48, 48, 0.5);
}

.input,
.texts {
  display: flex;
  flex-direction: column;
}

.left {
  width: 60%;
}

.rigth {
  margin-left: 50px;
}

.texts {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  width: 90%;
  border-radius: 7px;
}

.body {
  background-color: #f1f1f1;
  width: 800px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  flex-direction: row;
  align-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.input-spaces {
  margin: 5px;
}

.title-blue {
  color: #0d5388;
  font-size: 16px;
}
.span-title {
  color: #0d5388;
  width: 26px;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 7px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-title-variables {
  color: #0d5388;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 7px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.span-title-resume {
  margin-top: 10px;
  color: #0d5388;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 7px;
  display: flex;
}
