
.modal-overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
  
  .modal-box {
    display: block;
    background: white;
    width: 400px;
    height: 200px;
    padding: 1rem;
    border-radius: 1rem;
  }
  