.container-alert {
    width: 90%;
    display: flex;
    flex-direction: row;
    background-color: #f1f1f1;
    margin: 12px;
    border: 1px solid #d8d8d8;
    border-radius: 15px
}

.span-12 {
    font-size: 12px;
    color: #0D5388;
}

.div-text {
    padding: 8px;
    margin-right: 5px;
}