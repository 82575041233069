.App {
  text-align: center;
  background: linear-gradient(to bottom, #ffffff, #e0e0e0);
}

.App-header {
  background-color: #fdfdfd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}
.custom-phone-input .react-international-phone-input {
  background: transparent !important;
  border: none !important;
  color: black !important;
}

.custom-phone-input .react-international-phone-country-selector-button {
  background: transparent !important;
}

.custom-phone-input input {
  background: transparent !important;
  border: none !important;
  color: black !important;
}
.App-link {
  color: #61dafb;
}

.button {
  margin: 10px;
  width: 150px;
  border-radius: 4px;
  font-weight: bolder;
  background-color: var(--primary);
  color: #ffff;
  border-color: var(--primary);
  margin: 20px;
}

.accordeon-new {
  width: 800px;
  background-color: #fff;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-radius: 0px 0px 20px 20px;
}
