.draggable {
  align-items: center;
  flex-direction: column;
  display: flex;
  bottom: 10px;
  right: 10px;
  width: 459px;
  height: 300px;
  background: linear-gradient(
    to bottom left,
    rgba(0, 1, 66, 1) 0%,
    rgba(2, 114, 189, 1) 100%
  );
  position: fixed;
  z-index: 1000;
  border-radius: 0px 13px 13px 13px;
  overflow: hidden;
}

.draggable-sub {
  width: 98%;
  height: 98%;
  border: 2px solid #fff;
  padding: 5px;
  margin: 5px;
  border-radius: 0px 13px 13px 13px;
}

.handle-sub {
  width: 100%;
  height: 25px;
  cursor: move;
  text-align: end;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iframe-div {
  width: 100%;
  height: 100%;
  border-radius: 0px 13px 13px 13px;
  margin: 0px 4px 0px 0px;
  padding: 3px;
}
