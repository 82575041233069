@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #ffffff, #e0e0e0);
  padding: 0;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.row-align {
  display: flex;
  flex-direction: row;
}
.column-align {
  display: flex;
  flex-direction: column;
}

.left-align {
  text-align: left;
}

textarea,
select {
  width: 300px;
  height: 30px;
  border-radius: 4px;
}

select {
  border-radius: 8px !important;
}

.input-template {
  width: 300px;
  height: 30px;
  border-radius: 4px;
}

textarea {
  height: 103px;
  resize: 'none';
}

.table {
  color: #303030;
}

:root {
  --primary: #ee7923;
  --secondary: #fdfdfd;
  --bs-gray: #eeeeee;
  --blue: #048;
  --gradient-angle: 0deg;
}

.text-label {
  color: #303030;
}

.label {
  color: #303030;
}

.div-img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
}

.input-forms {
  display: flex;
  flex-direction: row;
}

.forms {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* th - cores alternadas: */
table th:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0);
  color: #6b97cf;
}

table th:nth-child(even) {
  background-color: rgba(0, 0, 0, 0);
  color: #315180;
}

.title_2024 {
  text-align: center;
  color: var(--blue);
}

.hr_color {
  height: 1.5px;
  background-image: linear-gradient(to right, #a050e0, #80a0ff);
  border: 0px;
}
.div_color {
  position: relative;
  background: linear-gradient(to right, #a050e0, #80a0ff);
  padding: 2px;
  border-radius: 8px;
}

.btn-grey {
  background-color: #006699 !important;
  color: #fff !important;
  padding: 0px !important;
}

.btn {
  border-radius: 20px;
}

.table-2024 {
  box-shadow: 0 0 0 1.5px #30c;
  border-radius: 10px;
  border-collapse: collapse;
}

.gradient-background {
  background: linear-gradient(90deg, #000f48, #013473, #0171bd);
}

.border_gradient {
  position: relative;
  background: linear-gradient(to right, #a050e0, #80a0ff);
  padding: 1.1px;
  border-radius: 8px;
  height: 31px;
}

.border_gradient-gray {
  position: relative;
  border: 1px solid #9b9b9b;
  border-radius: 7px;
  padding: 0px 4px;
  height: 23px;
}

.border_gradient::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 15px;
  background-image: conic-gradient(
    from var(--gradient-angle),
    var(--GlowBorder1, #57dcff) 0,
    var(--GlowBorder2, #51e6ff) 12.5%,
    var(--tGlowBorder3, #4939b4) 25%,
    var(--GlowBorder4, #47059e) 29.21%,
    var(--GlowBorder5, #8230ff) 74.83%,
    var(--GlowBorder1, #57dcff) 99%
  );
  z-index: -1;
  filter: blur(10px);
  opacity: 0.5;
}
.border_gradient-gray::after {
  content: '';
  position: absolute;
  border-radius: 15px;
  border: 1px solid #f0f0f0;
  z-index: -1;
}

.number_button_gradient {
  background: #fff;
  padding: 4px 8px;
  border-radius: 6px;
  color: #89aee7;
}

.number_button_gradient-gray {
  padding: 0px 3px;
  border-radius: 6px;
  color: #9b9b9b;
}

.color-text-label {
  font-size: 15px;
  color: #021c53;
  font-weight: bolder;
}

.table-2024 th {
  border-bottom: 2px solid #30c;
  vertical-align: middle;
}

.table-2024 th:not(:first-child):not(:last-child) {
  border-right: 1px solid #aaa;
}

.table-2024 tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

.table-2024 tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}
.quickReply-texts {
  text-align: center;
}
.quick-reply {
  color: blue;
  border-top: 1px solid gray;
  width: 100%;
  padding: 5px;
}
.quick-reply-teams {
  color: blue;
  border: 1px solid #d1d1d1;
  width: 100%;
  padding: 5px;
  margin: 10px;
  background-color: #fff;
}
.button-blue {
  background-color: #0d5388;
  color: #fff;
  font-weight: bolder;
  border-radius: 8px;
  font-size: 12px;
  border: none;
  width: 70px;
  height: 25px;
}

.table-container {
  max-height: 80vh;
  overflow-y: auto;
}

.fixed-header-table {
  width: 100%;
  border-collapse: collapse;
}

.tabela-wrapper {
  min-width: 90%;
  display: block;
}

.fixed-header-table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.fixed-header-table th,
.fixed-header-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.fixed-header-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.blue-text {
  color: #048;
}

.no-bullets {
  list-style: none;
  font-size: 12.6px;
  font-weight: bolder;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.button-next {
  height: 30px;
  border-radius: 10px;
  background-color: #0d5388;
  color: #fff;
  font-size: 13.6px;
  border: none;
  font-weight: bold;
}

.header-accordion {
  background-color: #342d69;
  width: 900px;
  height: 40px;
  color: #fff;
  border: 1px solid #fff;
  text-align: left;
  padding: 7px;
  cursor: pointer;
  font-weight: bolder;
  border-radius: 20px;
  margin-top: 10px;
  padding-left: 20px;
}

.disabled-button {
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  padding: 10px;
  width: 100px;
  height: 30px;
}

.button-disabled {
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  font-weight: bolder;
  border-radius: 8px;
  font-size: 12px;
  width: 70px;
  height: 25px;
}

.disabled-div-button {
  width: 120px;
}

.card_2024 {
  border-radius: 15px;
  background-color: #f1f1f1;
  border: 1px solid #d8d8d8;
  margin: 12px;
  align-items: center;
}

.border-bottom-zero {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion_head {
  border-radius: 25px;
  background-color: #707070;
  color: #fff;
  font-size: 1.1em;
  padding: 7px;
  padding-left: 20px;
  margin-top: 10px;
  user-select: none;
  width: 900px;
  height: 40px;
  border: 1px solid #fff;
  text-align: left;
  cursor: pointer;
  font-weight: bolder;
}

.accordion_head:hover {
  background-color: #888888;
}

.accordion_head img {
  height: 19px;
  margin-bottom: -5px;
  margin-right: 8px;
}

.accordion_head_opened {
  background: linear-gradient(
    6deg,
    rgba(5, 20, 110, 1) 0%,
    rgba(100, 150, 220, 1) 100%
  );
}

.accordion_chevron {
  float: right;
  margin-right: 15px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding-bottom: 10px;
}

.minus-delete {
  background-color: #d9534f;
  font-weight: bold;
  color: #fff;
  width: 18px;
  height: 28px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
}

.input-values {
  width: 250px;
  height: 30px !important;
  border: 1px solid #a8a8a8;
  margin: 5px;
  padding-left: 7px;
  border-radius: 8px !important;
}
.textarea-values {
  width: 350px;
  height: 70px !important;
  border: 1px solid #a8a8a8;
  margin: 5px;
  padding-left: 7px;
  border-radius: 8px !important;
}

.border-gray {
  border: 1px solid #aaa;
}

.button-save {
  margin: 5px;
  width: 80px;
  height: 30px;
  border-radius: 10px;
  background-color: #5ed12c;
  color: #fff;
  border: 1px solid #a8a8a8;
  font-size: 14px;
  font-weight: bolder;
}

.button-cancel {
  margin: 5px;
  width: 80px;
  height: 30px;
  border-radius: 10px;
  background-color: #df383b;
  color: #fff;
  border: 1px solid #a8a8a8;
  font-size: 14px;
  font-weight: bolder;
}

@-webkit-keyframes loader_spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.in_loader {
  display: inline-block;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 16px;
  height: 16px;
  -webkit-animation: loader_spin 2s linear infinite;
  animation: loader_spin 2s linear infinite;
}

.margin-rigth-10 {
  margin-right: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.width-95-perc {
  width: 95%;
}

.triangle-up {
  width: 7px;
  height: 7px;
  background-color: #0d5388;
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.triangle-down {
  width: 7px;
  height: 7px;
  background-color: #0d5388;
  clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
  cursor: pointer;
}

.switch {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: gray;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  transition: background-color 0.3s;
}

.switch-on {
  background-color: green;
}

.slider {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
}

.slider-off {
  transform: translateX(0);
}

.slider-on {
  transform: translateX(25px);
}
