.cells{
    padding: 20px;
}

.menu-line {
    cursor: pointer;
    border-bottom: 1px solid #DDD;    
    padding: 12px 16px;
}


